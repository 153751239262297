<template>
    <a
        class="px-2"
        href="tel:+1-888-994-4433"
        >Support: 888-994-4433</a
    >
    <a
        class="px-2"
        href="https://help.maricopa.edu/TDClient/36/DO/Requests/TicketRequests/New?ID=UwXocDmx5tY_&RequestorType=ServiceOffering"
        target="_blank"
        >Submit a Ticket</a
    >
    <a
        class="px-2"
        href="https://www.maricopa.edu/about/disclaimer"
        target="_blank"
        >Disclaimer</a
    >
    <a
        class="px-2"
        href="https://district.maricopa.edu/consumer-information/non-discrimination-statements"
        target="_blank"
        >Non-Discrimination Statements</a
    >
    <a
        class="px-2 lg:hidden"
        @click="navigate({ name: 'administrator-access' })"
        >Administrator Access</a
    >
</template>

<script setup>
    import { useNavigate } from '@/composables/navigate.js';

    const { navigate } = useNavigate();
</script>
